let apiBaseUrl = ''
let siteUrl = ''
let StoreID = ''
let appStage = process.env.REACT_APP_STAGE

if (appStage == 'dev') {
   //------live-url
   apiBaseUrl = 'https://api.rcfeadmin.com:3097'
   siteUrl = 'https://api.rcfeadmin.com'
   //------dev-url
   // apiBaseUrl = 'https://swapi1.glohtesting.com/' // previous-api-url
   // apiBaseUrl = 'https://swapi1.dreamztesting.com/'
   // siteUrl = 'https://swapi1.glohtesting.com/'
   //------local-url
   // apiBaseUrl = 'http://localhost:3087' 
   // siteUrl = 'http://localhost:3087'

} else if (appStage == 'stage') {
   // apiBaseUrl = 'https://swapi1.glohtesting.com:3097'
   // siteUrl = 'https://swapi1.glohtesting.com:3097'
   apiBaseUrl = 'https://api.rcfeadmin.com:3097'
   siteUrl = 'https://api.rcfeadmin.com'

} else if (appStage == 'prod') {
   apiBaseUrl = 'https://api.rcfeadmin.com:3097'
   siteUrl = 'https://api.rcfeadmin.com'
}

if (siteUrl == 'https://api.rcfeadmin.com') {
   StoreID = '140770' //--live
}
else {
   StoreID = '1' //--dev
}
export const API_BASE_URL = apiBaseUrl
export const SITE_BASE_URL = siteUrl
export const SITE_STORE_ID = StoreID